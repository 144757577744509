<template>
  <v-dialog v-model="dialog" />
</template>

<script>
export default {
  name: 'MShutdown',
  props: {
    time: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    dialog: false,
    shutdownTimeout: null
  }),
  watch: {
    time: {
      handler(newTime) {
        if (this.shutdownTimeout) {
          clearTimeout(this.shutdownTimeout)
        }
        if (newTime) {
          const [shutdownHours, shutdownMinutes] = newTime.split(':')
          const shutdownDate = new Date()
          shutdownDate.setHours(shutdownHours)
          shutdownDate.setMinutes(shutdownMinutes)
          const now = new Date()
          let timeToShutdown = shutdownDate - now
          if (timeToShutdown < 0) {
            timeToShutdown += 24 * 60 * 60 * 1000
          }
          this.shutdownTimeout = setTimeout(() => {
            window.parent.postMessage({ channel: 'mammutos-frame', key: 'shutdown' }, '*')
          }, timeToShutdown)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.shutdownTimeout) {
      clearTimeout(this.shutdownTimeout)
    }
  }
}
</script>
